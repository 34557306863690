import { Component, OnInit } from '@angular/core';

import { TransactionsService } from "../../shared/services/transactions.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-basic-requirement',
  templateUrl: './basic-requirement.component.html',
  styleUrls: ['./basic-requirement.component.css']
})
export class BasicRequirementComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();

  myVar1;
  myVar2;
  myVar3;
  myVar4;
  non_testator =""
  basic;
  new_form = 'no';
  editing = 'no';
  basic_doc_id;

  basicFormGroup: FormGroup;
  basicForm2Group: FormGroup;
  constructor(public transService: TransactionsService,
    private _formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.basicFormGroup = this._formBuilder.group({
      non_testator: [""],

    });

    this.basicForm2Group = this._formBuilder.group({
      myVar1: ["", Validators.compose([Validators.required])],
      myVar2: ["", Validators.compose([Validators.required])],
      myVar3: [""],
      myVar4: [""]

    });

    console.log("NEW FORM", this.new_form)


    this.transService
      .retrieve_basic_requirements()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
          this.myVar1 = data.above_minimum_age
          this.myVar3 = data.member_armed_forces
          this.myVar2 = data.mental_capacity
          this.non_testator = data.not_testator
        }
      })
  }

  edit_form() {
    this.new_form = 'yes'
    this.editing = 'yes'
  }

  save_basic() {
    let formData: any = this.basicFormGroup.value as {};
    let formData2: any = this.basicForm2Group.value as {};
    let non_testator = formData.non_testator || "";
    this.myVar1 = formData2.myVar1 || "";
    this.myVar2 = formData2.myVar2 || "";
    this.myVar3 = formData2.myVar3 || "";


    console.log("VALUE ", this.myVar1, this.myVar2, this.myVar3, non_testator);

    this.transService.save_basic_requirements(this.myVar1, this.myVar2, this.myVar3, non_testator)

    this.new_form = 'no'


    // this.newItemEvent.emit(this.myVar1);

    this.newItemEvent.emit("active");
    
  }

  update() {
    let formData: any = this.basicFormGroup.value as {};
    let non_testator = formData.non_testator;

    console.log("VALUE ", this.myVar1, this.myVar2, this.myVar3, non_testator, this.basic_doc_id);

    this.transService.update_basic_requirements(this.basic_doc_id, this.myVar1, this.myVar2, this.myVar3, non_testator)

    this.new_form = 'no'
    this.newItemEvent.emit("active");

  }



}
