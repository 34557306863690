import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { ChildrenService } from "../../shared/services/children.service";
// import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: "app-children-details",
  templateUrl: "./children-details.component.html",
  styleUrls: ["./children-details.component.css"],
})
export class ChildrenDetailsComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  name;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public child_service: ChildrenService
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      c_first_name: [""],
      c_mid_name: [""],
      c_last_name: [""],
      dob: [""],
      c_res_address: [""],
      c_mail_address: [""],
      c_digi_address: [""],
      child_address: [""],
    });

    this.child_service
      .retrieve_child_details()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id


        }
      })
  }

  c_first_name;
  c_mid_name;
  c_last_name;
  c_res_address;
  public modelChange(str: string) {
    this.c_first_name = this.fourthFormGroup.value.c_first_name;
    this.c_mid_name = this.fourthFormGroup.value.c_mid_name;
    this.c_last_name = this.fourthFormGroup.value.c_last_name;
    this.c_res_address = this.fourthFormGroup.value.c_res_address;

  }

  all_forms() {
    this.completed = true;
    this.state = "done";
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }


  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "no"
    this.new_form = 'yes'
  }

  edit_form() {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"

    
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.child_service.save_child_details(formData)

    this.new_form = 'no'

    this.callParent.emit("completed6");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.child_service.update_child_details(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed6");

  }
}
