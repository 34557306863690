import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatGridListModule } from "@angular/material/grid-list";
//import { MatStepperModule } from "@angular/material/stepper";
import { HomeComponent } from "./components/layout//home/home.component";
import {
  MatStepperModule,
  MatInputModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatMenuModule,
} from "@angular/material";
import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { MatRadioModule } from "@angular/material/radio";

import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  FormBuilder,
  FormsModule,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/layout/header/header.component";

import { FooterComponent } from "./components/layout/footer/footer.component";
import { CreateWillComponent } from "./create-will/create-will.component";
import { BannerComponent } from "./components/layout/banner/banner.component";
import { TrusteeComponent } from "./forms/trustee/trustee.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { SignupComponent } from "./signup/signup.component";
import { LoginComponent } from "./login/login.component";

import { environment } from "../environments/environment";

import { AngularFireModule } from "@angular/fire";
//import { AngularFireModule } from "angularfire2";

import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AuthService } from "./shared/services/auth.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { PreambleComponent } from "./preamble/preamble.component";
import { ChildrenDetailsComponent } from "./forms/children-details/children-details.component";
import { OtherDependantsComponent } from "./forms/other-dependants/other-dependants.component";
import { OtherBeneficiaryComponent } from "./forms/other-beneficiary/other-beneficiary.component";
import { GiftsComponent } from "./forms/gifts/gifts.component";

// import { AngularFirestore } from "angularfire2/firestore";
import { MatExpansionModule } from "@angular/material/expansion";
import { GuardianComponent } from "./forms/guardian/guardian.component";
import { ExecutorComponent } from "./forms/executor/executor.component";
import { TrustCreationComponent } from "./forms/trust-creation/trust-creation.component";
import { PensionComponent } from "./forms/pension/pension.component";
import { ResidualComponent } from "./forms/residual/residual.component";
import { WillReadingComponent } from "./forms/will-reading/will-reading.component";
import { FinalWishesComponent } from "./forms/final-wishes/final-wishes.component";
import { InstructionsComponent } from "./forms/instructions/instructions.component";

import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from '@angular/material/select';
// import { MatCalendar } from '@angular/material/datepicker';

import { SpousalComponent } from './forms/spousal/spousal.component';
import { ChildrenComponent } from './forms/children/children.component';
import { BasicRequirementComponent } from './forms/basic-requirement/basic-requirement.component';
import { PersonalDetailsComponent } from './forms/personal-details/personal-details.component';
import { ResidenceComponent } from './forms/residence/residence.component';
import { RelationshipComponent } from './forms/relationship/relationship.component';
import { DocumentComponent } from './document/document.component';
import { FinalDocumentsComponent } from './final-documents/final-documents.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatNativeDateModule } from '@angular/material';
// import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatNativeDateModule } from '@angular/material/core';
import { FuneralComponent } from './funeral/funeral.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { htmlToPdfmake } from 'html-to-pdfmake';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CreateWillComponent,
    BannerComponent,
    TrusteeComponent,
    SignupComponent,
    LoginComponent,
    PreambleComponent,
    ChildrenDetailsComponent,
    OtherDependantsComponent,
    OtherBeneficiaryComponent,
    GiftsComponent,
    GuardianComponent,
    ExecutorComponent,
    TrustCreationComponent,
    PensionComponent,
    ResidualComponent,
    WillReadingComponent,
    FinalWishesComponent,
    InstructionsComponent,
    SpousalComponent,
    ChildrenComponent,
    BasicRequirementComponent,
    PersonalDetailsComponent,
    ResidenceComponent,
    RelationshipComponent,
    DocumentComponent,
    FinalDocumentsComponent,
    FuneralComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
