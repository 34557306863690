// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {



    // apiKey: "AIzaSyBXswDAjq7oCbz0S07QanZjmM66zMCPvkY",
    // authDomain: "ewillapp-47285.firebaseapp.com",
    // databaseURL: "https://ewillapp-47285-default-rtdb.europe-west1.firebasedatabase.app",
    // projectId: "ewillapp-47285",
    // storageBucket: "ewillapp-47285.appspot.com",
    // messagingSenderId: "120509937601",
    // appId: "1:120509937601:web:0d43ed851074dbb612a140",
    // measurementId: "G-NN1JKD7H0V"
    apiKey: "AIzaSyA2sNA7YgMlEhaH1HOOyrhuhGkqkrjXok0",
    authDomain: "ewillnew-7eb38.firebaseapp.com",
    projectId: "ewillnew-7eb38",
    storageBucket: "ewillnew-7eb38.appspot.com",
    messagingSenderId: "185765703794",
    appId: "1:185765703794:web:4a9e4bf31e88f7c02d52eb",
    measurementId: "G-R3Z3CYZMXB"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
