
import { Injectable } from '@angular/core';

// import { HttpClient } from "@angular/common/http";
import { map, take, switchMap, tap, first } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  constructor(private authService: AuthService,

    private db: AngularFirestore) {


  }

  save_property(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("PROPERTY DATA::", data)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        gift_allocated: "no",
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("properties")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }

  new_save_property(data,date) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("PROPERTY DATA::", data)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        gift_allocated: "no",
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("properties")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }

  update_property(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("Update Data::", data)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("properties")
          .doc(doc_id)
          .update({
            data: data,
            gift_allocated: "no",
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }


  update_property_gift(doc_id, data) {
    console.log("><><>< UPDATING PROPERTY<><><><")
    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("properties")
          .doc(doc_id)
          .update({
            gift_allocated: data
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }


  retrieve_residence(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("properties", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }


  retrieve_residence_gifted(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("properties", (ref) => ref.where("userUID", "==", userUid).where("gift_allocated", "==", "no").orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }



  retrieve_residence_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("PROPERT ID::", data.id)

  //   let customerRef = this.db.collection("properties").doc(data.id);

  //  return customerRef.snapshotChanges().pipe(map(action => {
  //     const data = action.payload.data() as {};
  //     const id = action.payload.id;
  //     return { id, ...data };
  //   }));

    return this.db
      .collection("properties").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap =>
          {
            let data: any = snap.payload.data() as {};
            const id = snap.payload.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
       
      );
  }
}

