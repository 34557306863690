import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: 'app-funeral',
  templateUrl: './funeral.component.html',
  styleUrls: ['./funeral.component.css']
})
export class FuneralComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  trustee_trust_no;
  trustee_trust;
  ch_benefi;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  gifts_allo;
  bene;

  funeral_arrange = ''
  funeral_arrange_instructions = ''
 

  @Output() callParent = new EventEmitter();

  constructor(private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    private db: AngularFirestore) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      funeral_arrange: [""],
      funeral_arrange_instructions: [""], 

    });

    this.beneficiary_service
      .retrieve_funeral()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)



        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

          this.funeral_arrange = data.data.funeral_arrange
          this.funeral_arrange_instructions = data.data.funeral_arrange_instructions
         

        }
      })

  }



  form1() {
    console.log(this.firstFormGroup.value);
  }

  public modelChange(str: string) {

  }

  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.funeral_arrange = ""
    this.funeral_arrange_instructions = ""
  }



  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"

    this.beneficiary_service
      .retrieve_funeral_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id

        this.funeral_arrange = ""
        this.funeral_arrange_instructions = ""

        this.funeral_arrange = basic.data.funeral_arrange
        this.funeral_arrange_instructions = basic.data.funeral_arrange_instructions


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("funeral")
          .doc(data.id)
          .delete()
      });

    }
  }





  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_funeral(formData)

    this.new_form = 'no'

    this.callParent.emit("completed16");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.update_funeral(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed16");

  }

  
}
