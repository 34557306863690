import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { ChildrenService } from "../../shared/services/children.service";

import { SpousalService } from "../../shared/services/spousal.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AddressValidator } from "src/validators/digital";

@Component({
  selector: "app-children",
  templateUrl: "./children.component.html",
  styleUrls: ["./children.component.css"],
})
export class ChildrenComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address = '';
  trustee_trust_no;
  trustee_trust;
  ch_benefi;
  more_spouse;
  children;
  under_18 = '';
  addChild;

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  child_beneficiary = '';
  child_dependant = '';
  trustee = '';
  guardian = '';
  gender;
  digital;
  mail

  c_first_name = '';
  c_mid_name = '';
  c_last_name = '';
  c_res_address = '';
  dob = '';
  c_mail_address = '';
  c_digi_address = '';
  entryDate
  bene_instruction;;

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public child_service: ChildrenService,
    public spousal_service: SpousalService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({

      under_18: [""],
      trustee: [""],
      guardian: [""],
      child_beneficiary: [""],
      child_dependant: [""],
      bene_instruction: [""],
      c_first_name: ["", Validators.required],
      c_mid_name: [""],
      c_last_name: ["", Validators.required],
      dob: [""],
      c_res_address: [""],
      c_mail_address: [""],
      c_digi_address: ['', Validators.compose([Validators.required, AddressValidator.isValid])],
      child_address: [""],
      entryDate: new Date()
    });

    //RETRIEVE RELATIONSHIP DETAILS

    this.child_service
      .retrieve_child()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

       

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
          this.under_18 = data.data.under_18
          this.trustee = data.data.trustee
          this.child_beneficiary = data.data.child_beneficiary
          this.child_dependant = data.data.child_dependant
          this.c_first_name = data.data.c_first_name
          this.c_mid_name = data.data.c_mid_name
          this.c_last_name = data.data.c_last_name
          this.dob = data.data.dob
          this.c_res_address = data.data.c_res_address
          this.c_mail_address = data.data.c_mail_address
          this.c_digi_address = data.data.c_digi_address
          this.child_address = data.data.child_address
          this.bene_instruction = data.data.bene_instruction


        }
      })
    
    
    this.spousal_service
      .retrieve_personal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)

        let data = basic[0];


        if (basic.length > 0) {
          let data = basic[0];

          this.gender = data.data.gender
          this.residential = data.data.residential
          this.digital = data.data.digital
          this.mail = data.data.mail

          console.log("Gender:::", this.gender)
          console.log("residential:::", this.residential)
          console.log("digital:::", this.digital)
          console.log("mail:::", this.mail)

          this.fourthFormGroup.get('c_digi_address').setValue(this.digital);

        }
      })
    
   
    
  }

  form1() {
    console.log(this.firstFormGroup.value);
  }

  public modelChange(str: string) {
    // console.log("Name", this.firstFormGroup.value.name);
    // console.log("Name", this.firstFormGroup.value.age);
    // this.searchStr = this.firstFormGroup.value.name;
    // this.child_beneficiary = this.fourthFormGroup.value.child_beneficiary;
    // this.under_18 = this.fourthFormGroup.value.under_18;
    // this.trustee = this.fourthFormGroup.value.trustee;
    // Add code for searching here
    // console.log(this.searchStr);
    // console.log(this.age);
  }

  all_forms() {
    this.completed = true;
    this.state = "done";
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
    this.firstFormGroup.reset();
    this.fourthFormGroup.reset();
  }


  newChild() {

    // this.fourthFormGroup.reset()
    //  this.listProp = "yes"
    this.new_form = 'yes'
    this.addChild = "yes"
   

    this.under_18 = ""
    this.trustee = ""
    this.child_beneficiary = ""
    this.child_dependant = ""
    this.c_first_name = ""
    this.c_mid_name = ""
    this.c_last_name = ""
    this.dob = ""
    this.c_res_address = ""
    this.c_mail_address = ""
    this.c_digi_address = ""
    this.child_address = ""
    this.guardian = ""
    this.bene_instruction = ""

    this.showSave = "yes"

        
  }


  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"

    this.child_service
      .retrieve_children_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("CHILD SERVICE DATA ID:::", basic.id)
  
        this.basic_doc_id = basic.id

        this.under_18 = basic.data.under_18
        this.trustee = basic.data.trustee
        this.child_beneficiary = basic.data.child_beneficiary
        this.child_dependant = basic.data.child_dependant
        this.c_first_name = basic.data.c_first_name
        this.c_mid_name = basic.data.c_mid_name
        this.c_last_name = basic.data.c_last_name
        this.dob = basic.data.dob
        this.c_res_address = basic.data.c_res_address
        this.c_mail_address = basic.data.c_mail_address
        this.c_digi_address = basic.data.c_digi_address
        this.child_address = basic.data.child_address
        this.guardian = basic.data.guardian
        this.bene_instruction = basic.data.bene_instruction


      })
  }



  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);
 

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("children")
          .doc(data.id)
          .delete()
      });

    }



  }

  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.child_service.save_child(formData)

    this.new_form = 'no'

    this.callParent.emit("completed5");

    this.under_18 = ""
    this.trustee = ""
    this.child_beneficiary = ""
    this.child_dependant = ""
    this.c_first_name = ""
    this.c_mid_name = ""
    this.bene_instruction = ""
    this.c_last_name = ""
    this.dob = ""
    this.c_res_address = ""
    this.c_mail_address = ""
    this.c_digi_address = ""
    this.child_address = ""
    this.guardian = ""

    this.firstFormGroup.reset();
    


  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.child_service.update_child(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed5");

  }
}
