import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatExpansionModule } from "@angular/material/expansion";


import { PropertiesService } from "../../shared/services/properties.service";
import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { DependantsService } from "../../shared/services/dependants.service";
import { SpousalService } from "../../shared/services/spousal.service";
import { ChildrenService } from "../../shared/services/children.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-gifts",
  templateUrl: "./gifts.component.html",
  styleUrls: ["./gifts.component.css"],
})
export class GiftsComponent implements OnInit {
  panelOpenState = false;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  myVar1;
  myVar2;
  myVar3;
  myVar4;
  basic;
  no_gift_allocated;
  basic_doc_id
  bene;
  dependants
  organization_name
  items;
  gifts_allo;
  showSummary: boolean = true;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  ben_list;
  params;
  spouse_first_name
  spouse_l_name
  spouses;

  children_list;
  children = ""
  dep_name = [""];
  percentage = ""
  ben_name = [""];
  spouses_name = [""];
  child_percentage = ""
  // bene_percentage = ""
  dep_percentage = ""


  spouses_name_arr = [""];
  percentage_arr = [""];
  children_arr = [""];
  child_percentage_arr = [""];
  dep_name_arr = [""];
  dep_percentage_arr = [""];
  ben_name_arr = [""];
  userInput = [];
  instructions = [];
  bene_percentage = [];
  bene_percentage_arr = [""];

  showSummaryButton;

  property_name = ""
  beneficiary_names = [""];
  dependants_name = ""
  property_details = ""
  gift_doc_id;

  share = '';

  itemValues: any[] = [];
  indexxSaved;
  trustee;

  // public all_list: Array<any>;



  step = 0;
  all_list = [];
  all_list_with_instructions = [];
  items2 = ['Item 1', 'Item 2', 'Item 3'];
  single_instructions;
  guardian;
  // get benNameArray() {
  //   return this.fourthFormGroup.get('percc') as FormArray;
  // }

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public property_service: PropertiesService,
    public beneficiary_service: BeneficiariesService,
    public dependants_service: DependantsService,
    public spousal_service: SpousalService,
    public child_service: ChildrenService,
    private db: AngularFirestore, private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.fourthFormGroup = this._formBuilder.group({

      ben_name: [""],
      percentage: [""],
      dep_name: [""],
      spouses_name: [""],
      children: [""],
      bene_percentage: [""],
     // percc: this._formBuilder.array([]),
      dep_percentage: [""],
      child_percentage: [""],
      property_name: [""],
      property_details: [""],
      share: [""],
      instructions: [""]



    });
    this.cdr.detectChanges();

    const items = this.fourthFormGroup.value.ben_name;
    console.log("ITEMSSSSS:::", items)
    // items.forEach(() => {
    //   this.benNameArray.push(new FormControl(''));
    // });
  }

  ngOnInit() {

    this.cdr.detectChanges();

    //RETRIEVE RESIDENCE DETAILS

    this.property_service
      .retrieve_residence()
      .subscribe((basic) => {
        console.log("RESUDENT PROPERTTIES CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];
        // this.basic_doc_id = data.id
        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

        }
      })

    this.property_service
      .retrieve_residence_gifted()
      .subscribe((basic) => {
        console.log("PROPERTIES NOT GIFTED:::", basic)

        this.no_gift_allocated = basic
        console.log("LENGHT:::", this.no_gift_allocated.length)
     
      })

    



    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic2) => {
        console.log("BENE REQUIR:::", basic2)
        this.bene = basic2

        console.log("BENE REQUI his.beneR:::", this.bene)
        this.items = [];


        for (var name of this.bene) {
          console.log("BEN NAME", name);
          this.all_list.push({
            name: name.data.org_name || name.data.first_name + " " + name.data.last_name
          });
        }
        console.log("BENE all_list LISTSS::: ", this.all_list);

        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })


    this.beneficiary_service
      .retrieve_gift_allocation()
      .subscribe((basic2) => {
        console.log("ALLO CHECK BASIC REQUIR:::", basic2)
        let data = basic2[0];
        this.gifts_allo = basic2



        if (basic2.length > 0) {
          this.ben_name = data.data.ben_name
          this.percentage = data.data.percentage
          this.dep_name = data.data.dep_name
          this.spouses_name = data.data.spouses_name
          this.children = data.data.children
          this.bene_percentage = data.data.bene_percentage
          this.dep_percentage = data.data.dep_percentage
          this.child_percentage = data.data.child_percentage
          this.property_details = data.data.property_details
          this.property_name = data.data.property_name
          this.instructions = data.data.bene_instructions
          this.single_instructions = data.data.single_instructions

          console.log("bene_percentage:::", this.bene_percentage)
        }


      })

      // .retrieve_spousal()
    this.spousal_service
      .retrieve_spousal_as_dependant_no_instructions()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
        console.log("spouses all_list LISTSS::: ", this.all_list);


      })
    
    this.spousal_service
      .retrieve_spousal_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
      
      })

      // .retrieve_child_as_beneficiary()
    this.child_service
      .retrieve_child_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);

      })
    
    
    this.child_service
      .retrieve_child_as_dependant()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);

      })


   // retrieve_dependant_no_instructions
    this.dependants_service
      .retrieve_dependant_no_instructions()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.dependants = basic2

        for (var name of this.dependants) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);


        console.log("ITEM LISTSS::: ", this.items);

        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })
    
    this.beneficiary_service
      .retrieve_trustee_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        this.trustee = basic

        for (var name of this.trustee) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.f_name + " " + name.data.l_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);   
      })
      
   // retrieve_guardian_as_beneficiary()
    this.beneficiary_service
      .retrieve_guardian_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.guardian = basic
        let data = basic[0];

        for (var name of this.guardian) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.f_name + " " + name.data.l_name
          });
        }

      
      })
    
  

    console.log("WHAT IN all_list", this.all_list)

    //BENEFICCIARY WITH INSTRUCTIONS


    this.child_service
      .child_with_instructions()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
      
        let data = basic[0];

        for (var name of basic) {
          console.log("children_list NAME", name);
          this.all_list_with_instructions.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("all_list_with_instructions::: ", this.all_list_with_instructions);

      })
    
    
    this.spousal_service
      .spouse_with_instructions()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
     
        let data = basic[0];


        for (var name of basic) {
          console.log("spouses NAME", name);
          this.all_list_with_instructions.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
        console.log("all_list_with_instructions::: ", this.all_list_with_instructions);


      })
    
    

    
    this.dependants_service
      .dependant_with_instructions()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
 

        for (var name of basic2) {
          console.log("dependants NAME", name);
          this.all_list_with_instructions.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list_with_instructions);

      })

    

    //END BENEFICIRARY WITH INSTRUCTION

  }

  calculateSum(): number {
 
    return this.userInput.reduce((acc, num) => acc + num, 0);
  }

  calculateSum2(): number {
 
    return this.bene_percentage.reduce((acc, num) => acc + num, 0);
  }

 

  

  // handleInputChange(index: number, value: string) {
  //   this.userInput[index] = value;
  //   console.log("INPUT HANDLE::::", this.userInput[index])
  //   console.log("INPUT HANDLE VALUE::::", value)
  // }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);
    console.log("DELETE ID ", data.data.property_id);

    
    // console.log("Property Name ", data.data.property_name);
    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid

    if (userUid) {
      console.log("USER EXISTS--")
      this.property_service.update_property_gift(data.data.property_id, "no")

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("gift_allocation")
          .doc(data.id)
          .delete()
      });

    }


    
  }



  setStep(index: number) {
   
    this.step = index;
    this.userInput = [];
    this.instructions = [];
    this.bene_percentage = [""];
    this.beneficiary_names = [""];
    this.child_percentage = " "
    this.children = " "
    this.dep_percentage = " "
    this.dependants_name = " "
    this.percentage = " "
    this.property_details = " "
    this.property_name = " "
    this.spouses_name = [""];

    this.ben_name = [""];
    this.ben_name_arr = [""];
    this.share = " "
    this.userInput = [""];

    this.single_instructions = [""]
    console.log("Set step ??", this.step)

    console.log("WHAT IS userInput SAVED ??", this.userInput)
    if (this.indexxSaved == index) {
      this.indexxSaved = index
      console.log("WHAT IS INDEX SAVED ??", this.indexxSaved)
    }

  }



  edit_form(data) {
    this.new_form = 'no'
    this.editing = 'yes'
    this.showSave = "no"
    this.step++; 

    // this.bene_percentage =  [""];
    // this.beneficiary_names = [""];
    // this.child_percentage = " "
    // this.children = " "
    // this.dep_percentage = " "
    // this.dependants_name = " "
    // this.percentage = " "
    // this.property_details = " "
    // this.property_name = " "
    // this.spouses_name = [""];

    // this.ben_name = [""];
    // this.ben_name_arr = [""];
    // this.share = " "
    // this.userInput = [""];
    // this.instructions = [""]
    // this.single_instructions = [""]

    console.log("EDITING DATA--", data)
    console.log("EDITING--", this.editing)
    console.log("----ARRAY----", this.ben_name_arr[0])
    console.log("----ARRAY----", this.ben_name_arr)


    this.beneficiary_service
      .retrieve_gift_edit(data)
      .subscribe((basic) => {
        console.log("GIFT ALLO SERVICE:::", basic)
        this.gift_doc_id = basic.id


        this.bene_percentage = basic.data.bene_percentage
        this.beneficiary_names = basic.data.beneficiary_names
        this.child_percentage = basic.data.child_percentage
        this.children = basic.data.children
        this.dep_percentage = basic.data.dep_percentage
        this.dependants_name = basic.data.dependants_name
        this.percentage = basic.data.bene_percentage
        this.property_details = basic.data.property_details
        this.property_name = basic.data.property_name
        this.spouses_name = basic.data.spouses_name

        this.ben_name = basic.data.ben_name
        this.ben_name_arr = basic.data.ben_name
        this.share = basic.data.share
        this.userInput = basic.data.bene_percentage
        this.instructions = basic.data.bene_instructions
        this.single_instructions = basic.data.single_instructions

        console.log("BENE NAME:::", this.ben_name)
        console.log("BENE NAME LENGHT:::", this.ben_name.length)
        console.log("BENE PERCIENT:::", this.share)
        console.log("PERCENTAGESS:::", this.userInput)
        console.log("----ARRAY----", this.ben_name_arr[0])
        console.log("----ARRAY----", this.ben_name_arr)
        console.log("PERCENTAGESS 2:::", this.bene_percentage)
        console.log("instructions 2:::", this.instructions)
        console.log("single_instructions 2:::", this.single_instructions)


      })
  }




  proceed(id,data, details, index) {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("Property ID ", id);
    console.log("Property index ", index);
    console.log("Data ", data);
    console.log("details ", details);
    console.log("BENERFICIL ARRY ", this.ben_name_arr[index]);
    console.log("INSTRUCTIOSN ", this.instructions);
    console.log("SINGLE INSTRUCTIOSN ", this.single_instructions);
    console.log("userInput", this.userInput);
    

   


    this.params = {
      property_id: id,
      property_name: data,
      property_details: details,
      ben_name: this.ben_name_arr[index] || "",
      bene_percentage: this.userInput || "",
      share: this.share || "",
      bene_instructions: this.instructions || "",
      single_instructions: this.single_instructions || "",
 


    };

    


    console.log(" this.params ", this.params);

    this.beneficiary_service.save_gift_allocation(this.params)
    
    this.property_service.update_property_gift(id, "yes")

     this.new_form = 'no'
    this.editing = 'no'
    this.showSummary = false

    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)


    this.callParent.emit("completed9");

  }



  update() {
    let index = 0
   

    console.log("bene_percentage:::", this.bene_percentage, "OR ", "BEN NAME ARR::", this.ben_name_arr)
    
  

    if (this.ben_name_arr[index]) {
      this.params = {
        property_name: this.property_name,
        property_details: this.property_details,
        ben_name: this.ben_name_arr || "",
        bene_percentage: this.bene_percentage || "",
        share: this.share || "",
        bene_instructions: this.instructions || "",
        single_instructions: this.single_instructions || "",

      };
      console.log(" this.params ", this.params);

      this.beneficiary_service.update_gift_allocation(this.gift_doc_id, this.params)

      this.new_form = 'no'
      this.editing = 'no'
      
    }
    else {
      alert("Select a beneficiary to be able to update")
    }

    

  }

  // allocateNew() {
  //   console.log("<---- RESSETTIN FORM ---->>>")
  //   // this.fourthFormGroup.reset()


  //   this.property_name = ""
  //   this.property_details = ""
  //   this.dep_name = [""]
  //   this.ben_name = [""]
  //   this.spouses_name = [""]
  //   this.children = ""
  //   this.percentage = ""
  //   this.bene_percentage = [""]
  //   this.dep_percentage = ""
  //   this.child_percentage = ""


  //   this.new_form = 'yes'
  //   this.showSummary = false
  //   this.editing = 'no'
  //   this.showSummaryButton = "yes"


  //   console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)
  // }


  // GetStats(event) {

  //   this.ben_list = event.target.value
  //   console.log(this.ben_list);

  //   let ben_list = [];


  //   console.log(name);
  //   ben_list.push({
  //     name: this.ben_list


  //   });

  //   console.log("NEW BENLIST", ben_list);


  // }




  goToSummary() {

    this.showSummary = true
    this.new_form = 'no'
    this.listProp = "no"
    this.editing = 'no'

    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing, "gifts_allo?.length ", this.gifts_allo.length)


  }
}
