
import { Injectable } from '@angular/core';

// import { HttpClient } from "@angular/common/http";
import { map, take, switchMap, tap, first } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DependantsService {

  constructor(private authService: AuthService,

    private db: AngularFirestore) {


  }

  save_dependant(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("dependants")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }



  update_dependant(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("dependants")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  retrieve_dependants(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("dependants", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }


  retrieve_dependant_no_instructions(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("dependants", (ref) => ref.where("userUID", "==", userUid).where("data.bene_instruction", "==", "no").orderBy('entryDate', 'asc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }



  dependant_with_instructions(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("dependants", (ref) => ref.where("userUID", "==", userUid).where("data.bene_instruction", "==", "yes").orderBy('entryDate', 'asc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }


  retrieve_dependants_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("dependants").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }




}





