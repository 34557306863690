import { analyzeAndValidateNgModules, Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output, } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatRadioModule } from "@angular/material/radio";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PageEvent } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { PropertiesService } from "../../shared/services/properties.service";

import { AngularFirestore } from "@angular/fire/firestore";
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-residence',
  templateUrl: './residence.component.html',
  styleUrls: ['./residence.component.css']
})
export class ResidenceComponent implements OnInit {
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixFormGroup: FormGroup;

  address = '';
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  showSummary: boolean = true;
  state: string;
  title = "htmltopdf";
  public searchStr: string = "";
  public age;
  anothername: string;
  residential: string = "";
  public mid: string = "";
  lname;
  occupation;
  mail;
  digital;
  legalDocsname;
  gender;

  propertyList;
  sole_owned = '';
  owned = '';
 land_owned = '';
  property = '';

  married;
  spouse;
  spouse_name;
  spouse_m_name;
  spouse_l_name;
  spouse_maiden_name;
  residential_add;
  digital_add;
  dependants;
  children;
  under_18;
  trustee;
  Dependants_name;
  Dependants_m_name;
  Dependants_l_name;
  Dependants_ress_add;
  Dependants_ress_mail;
  Dependants_dig_add;
  deposing;

  executors_name;
  executors_m_name;
  executors_l_name;
  executors_resi;
  executors_mail_add;
  executors_digi_add;
  executors_occupation;
  move_land = '';
  no_property = '';
  
  more_spouse;
  any_spouse_dependant;
  spouse_beneficiary;

  funeral_exp = '';
  final_words = '';

  residual = '';

  myVar1;
  myVar2;
  myVar3;
  myVar4;

  listProp = "no";

  new_form = 'yes';
  editing;
  basic_doc_id;
  basic;

  property_name = '';
  property_details = '';
 
  land_property_details = '';
  land_property_name = '';
  propertyStake = '';
  landed
  movable;
  name = '';

  property_location = '';
  property_location_arr = [""];

  land_property_location = '';
  land_property_location_arr = [""];

  property_details_arr = [""];
  owned_arr = [""];
  propertyStake_arr = [""];
  sole_owned_arr = [""];

  land_property_details_arr = [""]
  land_property_name_arr = [""]
  land_owned_arr = [""]

  indexxSaved;
  showSummaryButton

  step = 0;
  edit_basic;
  directives;

  @Output() callParent = new EventEmitter();

  constructor(private _formBuilder: FormBuilder, public property_service: PropertiesService, private db: AngularFirestore, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

    this.cdr.detectChanges();

    // console.log("property_details_arr ", this.property_details_arr);
    // console.log("property_details_arr INDEX ", this.property_details_arr[0]);
    // Validators.required
    this.secondFormGroup = this._formBuilder.group({
    
      residential: [""],
      property: [""],
      owned: [""],
      sole_owned: [""],
      name: [""],
      property_details: [""],
      property_name: [""],
      move_land: [""],
      no_property: [""],
      land_owned: [""],
      propertyStake: [""],

      land_property_details: [""],
      land_property_name: [""],
      directives: [""],
      property_location: [""],
      land_property_location: [""],
    

    });
  


    //RETRIEVE RESIDENCE DETAILS

    this.property_service
      .retrieve_residence()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
       
        let data = basic[0];
        // this.basic_doc_id = data.id
        // console.log("LENGHT:::", this.basic.length)

        // if (basic.length > 0) {
        //   let data = basic[0];
        //   this.basic_doc_id = data.id
        
        //   this.address = data.data.address
        //   this.residential = data.data.residential
        //   this.property = data.data.property
        //   this.owned = data.data.owned
        //   this.sole_owned = data.data.sole_owned
    
        //   this.property_details = data.data.property_details
        //   this.property_name = data.data.property_name
        //   this.move_land = data.data.move_land
        //   this.no_property = data.data.no_property
        //   this.land_owned = data.data.land_owned
        //   this.propertyStake = data.data.propertyStake
        //   this.property_location = data.data.property_location
        //   this.land_property_location = data.data.land_property_location
        

        //   console.log("residential:::", this.residential)
        //   console.log("property:::", this.property)

        // }
      })
    
    
    this.landed = [
      {
        name: "Land"
      },
      {
        name: "Residential Building"
      },
       {
        name: "Commercial Building (Hotel/Hospital/Factory/Shop/Office/Restaurant/etc.)"
      },
      {
        name: "Farm "
      },
      {
        name: "Mine/Concession"
      },
      {
        name: "Dam"
      },
      {
        name: "Other"
      },
    ]
  
    
    this.movable = [
      {
        name: "Furniture (Living Room/Bed Room/Dinning Room/etc.)"
      },
      {
        name: "Jewelry (Watch/Ring/Ear Ring/Necklace/etc.)"
      },
      {
        name: "Bank/Financial Institution Investment (Treasury Bills/Mutual Funds/Savings/etc.)"
      },
      {
        name: "Business Investment (Shares) "
      },
      {
        name: "Cash"
      },
      {
        name: "Home Electronics (TV/Home Theatre System/etc.)"
      },
      {
        name: "Personal Electronics (Phone/Smart Watch/etc.)"
      },
      {
        name: "Electric Appliances (Fridge/Freezer/Washing Machine/Stove/Oven/Dish Washer/etc.)"
      },
      {
        name: "Kitchen Appliances (Blender/Toaster/Rice Cooker/Deep Fryer/Air Fryer/Kettle/etc.)"
      },
      {
        name: "Cooking Utensils"
      },
      {
        name: "Glassware (Drinking Glass/Wine Glass/etc.)"
      },
      {
        name: "Chinaware (Dinner Plates/Tea Pot/Tea set/Mugs/etc.)"
      },
      {
        name: "Silverware (Cutlery/etc.)"
      },
      {
        name: "Clothing"
      },
      {
        name: "Vehicle (Car/Truck/SUV/Van/Bus/Farm Vehicles\/Commercial Vehicles/etc.)"
      },
      {
        name: "Factory Equipment"
      },
      {
        name: "Farm Equipment"
      },
      {
        name: "Commercial Restaurant Equipment (Stove/Dish Washer/Cooking Utensils/Deep Fryers/etc.)"
      },
      {
        name: "Commercial Shop Merchandise (Clothing/Food Items/Jewelry/Building Materials/etc.)"
      },
      {
        name: "Hospital Equipment"
      },
      {
        name: "Artifacts (Painting/Sculpture/etc.)"
      },
      {
        name: "Other"
      },
    ]
  }





  public modelChange(str: string) {


    //SECOND FORM GROUP == RESIDENTIAL


  }






  edit_form(data) {

    this.cdr.detectChanges();
  
    this.showSummary = false
    this.editing = 'yes'
    this.new_form = 'yes'

    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "Editing::", this.editing)


    this.property_service
      .retrieve_residence_edit(data)
      .subscribe((basic) => {

        this.cdr.detectChanges();
        console.log("PROPERTY SERVICE:::", basic)
        this.edit_basic = basic
       
        
        this.basic_doc_id = this.edit_basic.id

        console.log("DATA :::", this.edit_basic.data)
        console.log("DOC IS :::", this.basic_doc_id)

        let test = JSON.stringify(this.edit_basic.data)
        let parrsse = JSON.parse(test)
    
        console.log("PArsed", parrsse.land_owned)
   
        this.residential = basic.data.residential
        this.property = basic.data.property
        this.owned = basic.data.owned
        this.sole_owned = basic.data.sole_owned

        this.property_details = basic.data.property_details
        this.property_name = basic.data.property_name
        this.move_land = this.edit_basic.data.move_land
        this.no_property = basic.data.no_property
        this.land_owned = basic.data.land_owned
        this.propertyStake = basic.data.propertyStake
        this.land_property_details = basic.data.land_property_details
        this.land_property_name = basic.data.land_property_name
        this.property_location = basic.data.property_location
        this.land_property_location = basic.data.land_property_location
        

        console.log("land_property_name:::", this.land_property_name)
        console.log("move_land:::", this.move_land)
        console.log("owned:::", this.owned)
        console.log("land_owned:::", this.land_owned)

      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);
    console.log("Property Name ", data.data.property_name);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
   

    if (userUid) {
     
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("properties")
          .doc(data.id)
          .delete()
        
        
        
        // const userCollectionRef2 = this.db.collection("gift_allocation", (ref) =>
        //   ref.where('userUID', '==', userUid)
        // );

        const userCollectionRef = this.db.collection("gift_allocation", (ref) =>
          ref.where('userUID', '==', userUid)
            .where('data.property_id', '==', data.id )
        );


        userCollectionRef.snapshotChanges().subscribe((userCollection) => {

          console.log("USer colection::", userCollection)
      
          userCollection.forEach((docChange) => {
          

            console.log('Data2:', docChange.payload.doc.data());

            this.db.doc(`${"gift_allocation"}/${docChange.payload.doc.id}`).delete();

          });


        });


      
        
      });    

    }
   
   
    
  }

  



  save_residence() {
    let formData: any = this.secondFormGroup.value as {};
    console.log("VALUE ", formData);

    this.property_service.save_property(formData)

    this.new_form = 'no'

    this.callParent.emit("completed2");

  }

  updateProp(data, index) {
    // let formData: any = this.secondFormGroup.value as {};
    if (data == 'none') {
      let form_data = {
        "residential": this.residential || "none",
        "property": this.property || "none",
        "move_land": this.move_land || "none",
        "property_name": data || "none",
        "property_details": this.property_details_arr[index] || "none",
        "property_details_arr": this.property_details_arr[index] || "none",
        "owned": this.owned_arr[index] || "none",
        "propertyStake": this.propertyStake_arr[index] || "none",
        "land_property_details": this.land_property_details_arr[index] || "none",
        "land_property_name": this.land_property_name_arr[index] || "none",
        "land_owned": this.land_owned_arr[index] || "none",
        "no_property": this.no_property || "none",
        "property_location": this.property_location_arr[index] || "none",
        "land_property_location": this.land_property_location_arr[index] || "none",
        
      }

  

      console.log("form_data ", form_data);
      this.property_service.new_save_property(form_data, new Date())
      this.callParent.emit("completed2");
      
    } else { 
     
      
      this.showSummaryButton = "yes"

    this.indexxSaved = index
   
      
      this.step++;
    


    console.log("Property Details ", this.property_details_arr[index]);
      console.log("Property Location ", this.property_location_arr[index]);
      console.log("Land Property Location ", this.land_property_location_arr[index]);
   
   
    let form_data = {
      "residential": this.residential || "yes",
      "property": this.property || "yes",
      "move_land": this.move_land,
      "property_name": data,
      "property_details": this.property_details_arr[index] || "none",
      "property_details_arr": this.property_details_arr[index] || "none",
      "owned": this.owned_arr[index] || "none",
      "propertyStake": this.propertyStake_arr[index] || "none",
      "land_property_details": this.land_property_details_arr[index] || "none",
      "land_property_name": this.land_property_name_arr[index] || "none",
      "land_owned": this.land_owned_arr[index] || "none",
      "no_property": this.no_property || "none",
      "property_location": this.property_location_arr[index] || "none",
      "land_property_location": this.land_property_location_arr[index] || "none",
      
    }

    
    
    console.log("form_data ", form_data);
      this.property_service.new_save_property(form_data, new Date())
      this.callParent.emit("completed2");



  }

  }


  setStep(index: number) {
    this.step = index;

    if (this.indexxSaved == index) {
      this.indexxSaved = index
    }
 
  }


  update() {
    this.showSummaryButton = "yes"

    this.showSummary = true
   
    this.new_form = 'no'

    this.step++;
    let formData: any = this.secondFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("this.basic_doc_id ", this.basic_doc_id);

    this.property_service.update_property(this.basic_doc_id, formData)

    this.new_form = 'no'


    this.callParent.emit("completed2");



    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid

    console.log("PROPERY VALUE ", formData.property);

    if (userUid) {

      return new Promise<any>((resolve, reject) => {


        if (formData.property == 'no') {
          const userCollectionRef = this.db.collection("gift_allocation", (ref) =>
            ref.where('userUID', '==', userUid)
          );

          userCollectionRef.snapshotChanges().subscribe((userCollection) => {
            userCollection.forEach((docChange) => {
              this.db.doc(`${"gift_allocation"}/${docChange.payload.doc.id}`).delete();
            });


          });

          const userCollectionRef1 = this.db.collection("properties", (ref) =>
            ref.where('userUID', '==', userUid)
          );

          userCollectionRef1.snapshotChanges().subscribe((userCollection) => {
            userCollection.forEach((docChange) => {
              this.db.doc(`${"properties"}/${docChange.payload.doc.id}`).delete();
            });


          });


          

        }

        else {
          
          const userCollectionRef = this.db.collection("gift_allocation", (ref) =>
            ref.where('userUID', '==', userUid)
          );

          userCollectionRef.snapshotChanges().subscribe((userCollection) => {
            userCollection.forEach((docChange) => {
              this.db.doc(`${"gift_allocation"}/${docChange.payload.doc.id}`).delete();
            });


          });
        }

        console.log("<><><><>---- DELETED <><><>");
      });

    }

  }

  goToSummary() {
   
    this.showSummary = true
    this.new_form = 'no'
    this.listProp = "no"
    this.editing = "yes"
    

    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)

  
  }

  newProperty() {

    console.log("<---- RESSETTIN FORM ---->>>")
    // this.secondFormGroup.reset()
   

    this.owned = ""
    this.sole_owned = ""
    this.residential = ""
    this.property = ""
    this.property_details = ""
    this.property_name = ""
    this.move_land = ""
    this.no_property = ""
    this.land_owned = ""
    this.propertyStake = ""
    this.land_property_details = ""
    this.land_property_name = ""
    this.property_details_arr = [""];
    this.owned_arr = [""];
    this.propertyStake_arr = [""];
    this.sole_owned_arr = [""];

    this.land_property_details_arr = [""]
    this.land_property_name_arr = [""]
    this.land_owned_arr = [""]

    this.property_location = ""
    this.property_location_arr = [""];

    this.land_property_location = "";
    this.land_property_location_arr = [""];


    this.listProp = "yes"
    this.new_form = 'yes'
    this.showSummary = false
    this.editing = 'no'
    this.showSummaryButton = "no"


    console.log("showSummary::", this.showSummary, "new_form::", this.new_form, "listProp::", this.listProp, "editing:::", this.editing)
  }




}
