import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) { }


  gotoPage() {
    console.log("Clicking")
    // window.location.href = 'test.html';
  }

  ngOnInit(): void {
 
    // this.loadScriptInIframe('https://vfgh-test.telenity.com/registerhelper4/Register.js');
  }


  ngAfterViewInit(): void {
    // this.loadScript('https://vfgh-test.telenity.com/registerhelper4/Register.js');
    
   
  }


  
  private loadScript(scriptUrl: string): void {
    // const script = document.createElement('script');
    const script = this.renderer.createElement('script');
    script.id = 'script';
    script.type = 'text/javascript';
    script.src = scriptUrl; 
   
    script.defer = true;
    script.async = false;

    script.onload = () => {
      console.log("Script successfully loaded")
      // (window as any).widgetCreate();
      // const temp = document.body.appendChild(script);
      // console.log("temp::", temp)
    };

    //const temp = document.body.appendChild(script);
    this.renderer.appendChild(document.body, script);
  
   
  }


  private loadScriptInIframe(scriptContent: string): void {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument;
    iframeDoc.open();
    iframeDoc.write(`<script>${scriptContent}</script>`);
    iframeDoc.close();
  }

}
