import { Injectable } from '@angular/core';

// import { HttpClient } from "@angular/common/http";
import { map, take, switchMap, tap, first } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {

  constructor(private authService: AuthService,

    private db: AngularFirestore) {


  }

  save_dependant(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("beneficiaries")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }



  update_dependant(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("beneficiaries")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  retrieve_beneficiaries(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("beneficiaries", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),
        //first()
      );
  }

  retrieve_beneficiaries_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("beneficiaries").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }


  retrieve_exec_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("executors").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }

  retrieve_guard_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("guardian").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }


  retrieve_trust_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("trust_creation").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }


  retrieve_trustee_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("trustee").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }

  retrieve_will_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("will_reading").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }



  retrieve_funeral_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("CHILD ID::", data.id)


    return this.db
      .collection("funeral").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }

  




  //EXECUTORS
  retrieve_executors(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("executors", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_executors(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("executors")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_executors(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("executors")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  //guardian
  retrieve_guardian(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("guardian", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  retrieve_guardian_as_beneficiary(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("guardian", (ref) => ref.where("userUID", "==", userUid).where("data.beneficiary", "==", "yes").orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_guardian(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("guardian")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_guardian(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("doc_id UID::", doc_id)
    console.log("data UID::", data)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("guardian")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  //TRUST CREATION
  retrieve_trust(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("trust_creation", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_trust(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("trust_creation")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_trust(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("trust_creation")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  //TRUSTEE
  retrieve_trustee(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("trustee", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  retrieve_trustee_as_beneficiary(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("trustee", (ref) => ref.where("userUID", "==", userUid).where("data.beneficiary", "==", "yes").orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_trustee(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("trustee")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_trustee(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("trustee")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }




  //PENSION
  retrieve_pension(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("pension", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_pension(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("pension")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_pension(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("pension")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }





  //residual
  retrieve_residual(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("residual", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_residual(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("residual")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_residual(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("residual")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }




  //will_reading
  retrieve_will(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("will_reading", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }

  //funeral
  retrieve_funeral(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("funeral", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_will(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("will_reading")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }

  save_funeral(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("funeral")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_will(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("will_reading")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  update_funeral(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("funeral")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }




  //FINAL WISHES
  retrieve_final(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("final_wishes", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_final(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("final_wishes")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_final(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("final_wishes")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }






  //special_instructions
  retrieve_special_instructions(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("special_instructions", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }


  save_special_instructions(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("special_instructions")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }


  update_special_instructions(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("special_instructions")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



  //GIFT ALLOCATION

  save_gift_allocation(data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    if (userUid) {
      let basic_requirements = {
        userUID: userUid,
        data,
        entryDate: new Date()
      }

      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("gift_allocation")
          .add({ ...basic_requirements })
          .then(
            (res) => resolve(res),
            (err) => reject(err)
          );
      });
    }
  }



  retrieve_gift_allocation(): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    return this.db
      .collection("gift_allocation", (ref) => ref.where("userUID", "==", userUid).orderBy('entryDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((snap) => {
            let data: any = snap.payload.doc.data() as {};
            const id = snap.payload.doc.id;
            data.id = id;
            return {
              id: id,
              ...data,
            };
          })
        ),

      );
  }



  retrieve_gift_edit(data): Observable<any> {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)
    console.log("PROPERT ID::", data.id)


    return this.db
      .collection("gift_allocation").doc(data.id)
      .snapshotChanges()
      .pipe(map(snap => {
        let data: any = snap.payload.data() as {};
        const id = snap.payload.id;
        data.id = id;
        return {
          id: id,
          ...data,
        };
      })

      );
  }



  update_gift_allocation(doc_id, data) {

    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid
    console.log("USER UID::", userUid)

    if (userUid) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("gift_allocation")
          .doc(doc_id)
          .update({
            data: data,
            entryDate: new Date()
          })

          .then(
            (res) => {
              console.log(res);
              resolve(res);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });

    }
  }



}






