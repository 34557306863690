import { Component, OnInit, Input } from '@angular/core';
import { ViewChild, ElementRef } from "@angular/core";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PersonalDetailsService } from "../shared/services/personal-details.service";
import { BeneficiariesService } from "../shared/services/beneficiaries.service";
import { SpousalService } from "../shared/services/spousal.service";
import { ChildrenService } from "../shared/services/children.service";
import { PropertiesService } from "../shared/services/properties.service";
import { DependantsService } from "../shared/services/dependants.service";
import { Router, ActivatedRoute } from '@angular/router';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  @ViewChild("pdfTable") pdfTable: ElementRef;
  basic;
  basic2;
  basic3;
  basic4;
  basic5;
  basic6;
  basic7;
  basic8;
  basic9;
  basic10;
  trust_name;
  basic11;
  basic12;
  basic13;
  basic14;
  property;

  public items: any;

  @Input() msg: any[];
  // @Input('childToMaster') masterName: string;

  @Input() messagelist: any[];

  constructor(public personDetails: PersonalDetailsService, public beneficiary_service: BeneficiariesService, public spousal_service: SpousalService, public child_service: ChildrenService, public property_service: PropertiesService, public dependant_service: DependantsService, public router: Router) {
    console.log("Messge Input" + this.msg)
  }

  ngOnInit() {
    console.log("Messge Input" + this.msg)
    this.personDetails
      .retrieve_personal()
      .subscribe((basic) => {
        this.basic = basic[0]
      })

    this.beneficiary_service
      .retrieve_executors()
      .subscribe((basic) => {
        console.log("EXECUTORS:", basic)
        this.basic2 = basic
        console.log("EXECUTORS LENGHT:", this.basic2.length)



      })

    this.beneficiary_service
      .retrieve_trustee()
      .subscribe((basic) => {
        this.basic3 = basic

      })

    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        this.basic4 = basic
        console.log("SPOUSAL DETAILS basic:::", basic)
        console.log("SPOUSAL DETAILS:::", this.basic4)

      })

    this.child_service
      .retrieve_child_details()
      .subscribe((basic) => {
        this.basic5 = basic
        console.log("CHILDRENR:::", this.basic5)

      })

    this.beneficiary_service
      .retrieve_will()
      .subscribe((basic) => {

        this.basic6 = basic
        console.log("WILL READING:::", this.basic6)


      })

    this.property_service
      .retrieve_residence()
      .subscribe((basic) => {
        this.basic7 = basic
        console.log("PROPERTIES DETAILS:::", this.basic7)
        let data = this.basic7[0];
        this.property = data.data.property_name
        console.log("property DETAILS:::", this.property)
        console.log("property DETAILS:::", this.property.length)
      })


    this.beneficiary_service
      .retrieve_gift_allocation()
      .subscribe((basic2) => {

        this.basic8 = basic2
        console.log("GIFT ALLOCATION DETAILS:::", this.basic8)
       
        this.items = [];


        for (let d of this.basic8) {

          for (let f of d.data.ben_name) {
            for (let g of d.data.bene_percentage) {
              console.log("Ben Name", f);
              console.log("Ben %", g);
              // console.log("Data Ben Name", d.data.ben_name);
              // console.log("Data bene_percentage", d.data.bene_percentage);
              this.items.push({
                ben_name: f + "" + g

              });

            }

          }
        }
        console.log("DATASOURCE:::", this.items);

        // res.forEach((snap) => {
        //   console.log("ONLY SNAP:::", snap);

        //   this.dataSource = []; //For values
        //   let dataKeys = []; //For keys

        //   for (let key in snap) {
        //     console.log("KEYS IN SNAP:::", key);
        //     this.dataSource.push(snap[key]);
        //     dataKeys.push(key);
        //     console.log("PUSHING KEYS:::", dataKeys.push(key));
        //   }

        //   for (let d of this.dataSource) {
        //     console.log("Data Values", d);
        //   }

        //   // this.items.push({
        //   //   client_key: snap.key,
        //   //   Client_Message: snap.val().Client_Message,
        //   // });
        //   // this.items;
        //   console.log("DATASOURCE:::", this.dataSource);

        //   return false;
        // });


      })

    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic) => {
        this.basic9 = basic
      })


    this.beneficiary_service
      .retrieve_trust()
      .subscribe((basic) => {
        this.basic10 = basic
        this.trust_name = basic[0]

        console.log("REtirveied TRUSTSS::", this.basic10)
      })


    this.dependant_service
      .retrieve_dependants()
      .subscribe((basic) => {
        this.basic11 = basic
      })

    this.beneficiary_service
      .retrieve_residual()
      .subscribe((basic) => {
        this.basic12 = basic
        console.log("REtirveied RESIDUAL::", this.basic12)
      })

    this.beneficiary_service
      .retrieve_final()
      .subscribe((basic) => {
        this.basic13 = basic
        console.log("REtirveied WISHES::", this.basic13)

      })



    this.beneficiary_service
      .retrieve_pension()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic14 = basic
        console.log("REtirveied PENSION::", this.basic14)
        console.log("LENGHT:::", this.basic14.length)
        // let data = basic[0];

        // console.log("LENGHT:::", this.basic.length)

        // if (basic.length > 0) {
        //   let data = basic[0];
        //   this.basic_doc_id = data.id



        //   this.registered_plan = data.data.registered_plan
        //   this.change_beneficiary = data.data.change_beneficiary
        //   this.instructions = data.data.instructions

        //   this.list_ben = data.data.list_ben


        // }
      })
  }

  backk() {
    this.router.navigate(["/CreateWill"]);
  }



  // public downloadAsPDF() {
  //   const doc = new jsPDF();

  //   const pdfTable = this.pdfTable.nativeElement;

  //   var html = htmlToPdfmake(pdfTable.innerHTML);

  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).open();
  // }




  downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;

    // const documentDefinition = { content: htmlToPdfmake(pdfTable.innerHTML) };

    // pdfMake.createPdf(documentDefinition).open();

    //const element = document.getElementById('pdfTable'); // Replace 'content' with the ID of the HTML element you want to convert

    // const content = element.innerHTML;

    const docDefinition = {
      content: htmlToPdfmake(pdfTable.innerHTML),
      styles: {
        will_row2: {
          position: 'absolute',
          width: 100,

        },
        column_6: {
          width: 50,

        }
        // Define your CSS styles here
      }
    };

    pdfMake.createPdf(docDefinition).open(); // or use .download('document.pdf') to download the PDF directly





    // const pdf = new jsPDF();

    // const element = document.getElementById('pdfTable'); // Replace 'content' with the ID of the HTML element you want to convert

    // const options = {
    //   background: 'white',
    //   scale: 1, // Adjust the scale factor as needed to fit the content within the PDF page
    //   logging: true,
    //   useCORS: true
    // };

    // const contentHeight = element.offsetHeight;
    // const pageHeight = pdf.internal.pageSize.getHeight();
    // const maxImgHeight = pageHeight - 10; // Adjust the margin as needed

    // let position = 0;

    // html2canvas(element, options).then((canvas) => {
    //   while (position < contentHeight) {
    //     const imgData = canvas.toDataURL('image/png');
    //     const imgWidth = pdf.internal.pageSize.getWidth();
    //     const imgHeight = Math.min(canvas.height * imgWidth / canvas.width, maxImgHeight);

    //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     position += imgHeight;

    //     if (position < contentHeight) {
    //       pdf.addPage();
    //     }
    //   }

    //   pdf.save('document.pdf');
    // });
  }


  generatePDF() {
    const element = document.getElementById('pdfTable');

    const options = {
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Get the content HTML and apply inline styles to it
    const content = `
    <style>
      h1 { color: red; font-size: 24px; } 
      p { color: blue; font-size: 16px; }
    </style>
    ${element.innerHTML}
  `;

    // html2pdf().set(options).from(content).save();
    html2pdf().set(options).from(element).save();
  }


}

