import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { SpousalService } from "../../shared/services/spousal.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AddressValidator } from "src/validators/digital";

@Component({
  selector: "app-spousal",
  templateUrl: "./spousal.component.html",
  styleUrls: ["./spousal.component.css"],
})
export class SpousalComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address = '';
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  trustee_trust_no;
  trustee_trust;
  ch_benefi;
  more_spouse = '';
  any_spouse_dependant = '';
  spouse_beneficiary = '';
  spouse = '';
  spouse_ben = '';


  addSpo;

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  gender;
  digital;
  mail;
  spouse_maiden_name = '';
  mail_address = '';
  digi_address = '';
  spouse_address = '';
  spouse_first_name = '';
  spouse_m_name = '';
  spouse_l_name = '';
  res_address = '';
  bene_instruction;

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public spousal_service: SpousalService, private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      spouse_first_name: ["", Validators.required],
      spouse: [""],
      bene_instruction: [""],
      spouse_l_name: ["", Validators.required],
      spouse_maiden_name: [""],
      res_address: [""],
      mail_address: [""],
      digi_address: ['', Validators.compose([Validators.required, AddressValidator.isValid])],
      spouse_m_name: [""],
      more_spouse: [""],
      any_spouse_dependant: [""],
      spouse_beneficiary: [""],
      spouse_ben: [""],
      spouse_address: [""]
    });



    //RETRIEVE RELATIONSHIP DETAILS

    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
    
        let data = basic[0];

        console.log("SPOUSAL LENGHT:::", this.basic.length)
       
      
        

        if (basic.length > 0) {
          let data = basic[0];

          console.log("MOre Spouse:::", basic[0].data.more_spouse)
          this.basic_doc_id = data.id  

          this.spouse_first_name = data.data.spouse_first_name
          this.spouse = data.data.spouse
          this.spouse_l_name = data.data.spouse_l_name
          this.spouse_maiden_name = data.data.spouse_maiden_name
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.spouse_m_name = data.data.spouse_m_name
          this.more_spouse = data.data.more_spouse
          this.any_spouse_dependant = data.data.any_spouse_dependant
          this.spouse_beneficiary = data.data.spouse_beneficiary
          this.spouse_ben = data.data.spouse_ben
          this.spouse_address = data.data.spouse_address
          this.bene_instruction = data.data.bene_instruction


          console.log("res_address:::", this.res_address)
          console.log("spouse_address:::", this.spouse_address)
          console.log(" IS spouse a dependan:::", this.spouse)

        }
      })

    this.spousal_service
      .retrieve_personal()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)

        let data = basic2[0];


        if (basic2.length > 0) {
          let data = basic2[0];

          this.gender = data.data.gender
          this.residential = data.data.residential
          this.digital = data.data.digital
          this.mail = data.data.mail

          console.log("Gender:::", this.gender)
          console.log("residential:::", this.residential)
          console.log("digital:::", this.digital)
          console.log("mail:::", this.mail)

        }
      })
  }




  newProperty2() {

    // this.fourthFormGroup.reset()
    //  this.listProp = "yes"
    this.new_form = 'yes'
    this.addSpo = "yes"
    console.log(this.showSave)
    this.spouse_first_name = ""
    this.spouse = ""
    this.spouse_l_name = ""
    this.spouse_maiden_name = ""
    // this.res_address = ""
    // this.mail_address = ""
    // this.digi_address = ""
    this.spouse_m_name = ""
    this.any_spouse_dependant = ""
    this.spouse_beneficiary = ""
    this.spouse_ben = ""
    this.spouse_address = ""
    this.bene_instruction = ""
    

    console.log("more_spouse:::", this.more_spouse)
  }

 


  public modelChange(str: string) {


    //SECOND FORM GROUP == RESIDENTIAL

    // this.any_spouse_dependant = this.fourthFormGroup.value.any_spouse_dependant;
    // this.more_spouse = this.fourthFormGroup.value.more_spouse;
    // this.spouse_first_name = this.fourthFormGroup.value.spouse_first_name;
    // this.spouse_m_name = this.fourthFormGroup.value.spouse_m_name;
    // this.spouse_l_name = this.fourthFormGroup.value.spouse_l_name;
    // this.res_address = this.fourthFormGroup.value.res_address;




  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);
    console.log("DELETE USER ID ", data.userUID);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {


        this.db
          .collection("spousal")
          .doc(data.id)
          .delete()
        
        // const userCollectionRef = this.db.collection("spousal", (ref) =>
        //   ref.where('userUID', '==', data.userUID)
        // );

        // userCollectionRef.snapshotChanges().subscribe((userCollection) => {
        //   userCollection.forEach((docChange) => {
       
        //     this.db.doc(`${"spousal"}/${docChange.payload.doc.id}`).delete();
        //   });


        // });
        


        
        
        
      });
      

    }
  }

  all_forms() {
    this.completed = true;
    this.state = "done";


  }


  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }



  edit_form(data) {
    this.new_form = 'yes';
    this.editing = 'yes';
    this.showSave = "no";
    this.addSpo = "no"
    this.addSpo = "yes"

    console.log("New form?", this.new_form)

    this.spousal_service
      .retrieve_spousal_edit(data)
      .subscribe((basic) => {
        console.log("SPOUSAL SERVICE:::", basic)
        console.log("SPOUSAL SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id

  
        this.spouse_first_name = basic.data.spouse_first_name
        this.spouse = basic.data.spouse
        this.spouse_l_name = basic.data.spouse_l_name
        this.spouse_maiden_name = basic.data.spouse_maiden_name
        this.res_address = basic.data.res_address
        this.mail_address = basic.data.mail_address
        this.digi_address = basic.data.digi_address
        this.spouse_m_name = basic.data.spouse_m_name
        this.more_spouse = basic.data.more_spouse
        this.any_spouse_dependant = basic.data.any_spouse_dependant
        this.spouse_beneficiary = basic.data.spouse_beneficiary
        this.spouse_ben = basic.data.spouse_ben
        this.spouse_address = basic.data.spouse_address
        this.bene_instruction = basic.data.bene_instruction

        console.log("SPOUSAL SERVICE more_spouse :::", this.more_spouse)
        console.log("any_spouse_dependant:::", this.any_spouse_dependant)

        


      })
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("DATA VALUE ", this.fourthFormGroup.value);
    console.log("DATA VALUE ", this.fourthFormGroup);
    console.log("res_address VALUE ", this.res_address);
    console.log("res_address VALUE ", this.residential);
    
  
    this.spousal_service.save_spousal(formData)

    this.new_form = 'no'
    this.callParent.emit("completed4");  

    console.log("AFTER SAVING");
  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.spousal_service.update_spousal(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'
    this.callParent.emit("completed4");
  }
}
