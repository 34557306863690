import { Component, OnInit } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  title = "firebaseLogin";

  selectedVal: string;
  responseMessage: string = "";
  responseMessageType: string = "";
  emailInput: string;
  passwordInput: string;
  isForgotPassword: boolean;
  userDetails: any;

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(public authService: AuthService, private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;



    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.authService.SignUp(this.f.email.value, this.f.password.value)
      .then(
        data => {
          console.log("DATA IS", data)
          console.log("RETURN IS", this.returnUrl)
          // this.router.navigate([this.returnUrl]);
          this.router.navigate(["/CreateWill"]);
        },
        error => {
          console.log("ERROR", error)
          this.loading = false;
        });
  }
}