import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { DependantsService } from "../../shared/services/dependants.service";
import { SpousalService } from "../../shared/services/spousal.service";
import { ChildrenService } from "../../shared/services/children.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-pension",
  templateUrl: "./pension.component.html",
  styleUrls: ["./pension.component.css"],
})
export class PensionComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  trustee_trust_no;
  trustee_trust;
  ch_benefi;
  ch_benefi2;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  bene;
  items;

  registered_plan = ''
  change_beneficiary = ''
  instructions = ''
  list_ben = ''

  all_list = [];
  spouses;

  spouse_first_name;
  spouse_l_name;
  children_list;
  dependants;


  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    public dependants_service: DependantsService,
    public spousal_service: SpousalService,
    public child_service: ChildrenService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      registered_plan: [""],
      change_beneficiary: [""],
      instructions: [""],

      list_ben: [""]
    });


    this.beneficiary_service
      .retrieve_pension()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

         

          this.registered_plan = data.data.registered_plan
          this.change_beneficiary = data.data.change_beneficiary
          this.instructions = data.data.instructions

          this.list_ben = data.data.list_ben


        }
      })



    

    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic2) => {
        this.bene = basic2
        this.items = [];
        for (var name of this.bene) {
          console.log("BEN NAME", name);
          this.all_list.push({
            name: name.data.org_name || name.data.first_name + " " + name.data.last_name
          });
        }

      })
    
    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
  

      })


    this.child_service
      .retrieve_child()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
  
      })



    this.dependants_service
      .retrieve_dependants()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.dependants = basic2

        for (var name of this.dependants) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);

      })
  




    console.log("WHAT IN all_list", this.all_list)
  }



  form1() {
    console.log(this.firstFormGroup.value);
  }

  public modelChange(str: string) {

  }

  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "no"
    this.new_form = 'yes'
  }



  edit_form() {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_pension(formData)

    this.new_form = 'no'
    this.callParent.emit("completed14");

  }


  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("pension")
          .doc(data.id)
          .delete()
      });

    }
  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("basic_doc_id ", this.basic_doc_id);

    this.beneficiary_service.update_pension(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'
    this.callParent.emit("completed14");

  }


}
