import { FormControl } from '@angular/forms';

export class AddressValidator {

    static isValid(control: FormControl) {
        // const re = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(control.value);

        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const re = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        if (re) {
            return null;
        }

        return {
            // tslint:disable-next-line: object-literal-key-quotes
            'invalidEmail': true
        };

    }
}
