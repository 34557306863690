import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AddressValidator } from "src/validators/digital";
import { SpousalService } from "../../shared/services/spousal.service";

@Component({
  selector: "app-trustee",
  templateUrl: "./trustee.component.html",
  styleUrls: ["./trustee.component.css"],
})
export class TrusteeComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  name;
  addG;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  trust_creat;
  f_name = ''
  M_name = ''
  l_name = ''
  res_address = ''
  mail_address = ''
  digi_address = ''
  occupation = ''
  step = 0;
  trust;
  spouse_maiden_name = '';
  spouse_address = '';
  spouse_first_name = '';
  spouse_m_name = '';
  spouse_l_name = '';
  basic2;
  basic3;
  basic_exec;
  beneficiary;
  showOthers = 'yes'

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    private db: AngularFirestore,
    public spousal_service: SpousalService,
  ) { }

  ngOnInit() {
    this.thirdFormGroup = this._formBuilder.group({
      f_name: ["", Validators.required],
      M_name: [""],
      l_name: ["", Validators.required],
      res_address: [""],
      beneficiary: [""],
      mail_address: [""],
      digi_address: ['', Validators.compose([Validators.required, AddressValidator.isValid])],
      occupation: [""],
      trust: ["", Validators.required],

    });

    this.beneficiary_service
      .retrieve_trustee()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

          this.f_name = data.data.f_name
          this.M_name = data.data.M_name
          this.l_name = data.data.l_name
          
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.occupation = data.data.occupation
          this.beneficiary = data.data.beneficiary


        }
      })


    this.beneficiary_service
      .retrieve_trust()
      .subscribe((basic) => {
        console.log("COMMING FROM TRUST:::", basic)
        this.trust_creat = basic
        let data = basic[0];

        console.log("LENGHT:::", this.trust_creat.length)

    
      })




    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic2 = basic

        let data = basic[0];

        console.log("SPOUSAL LENGHT:::", this.basic.length)

    
      })


    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic3 = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic3.length)

      
       
      })
    
    

    this.beneficiary_service
      .retrieve_executors()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic_exec = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)
      })
  }



  public modelChange(str: string) {

  }

  setStep(index: number) {
    this.step = index;



  }


  show(data) {
    console.log("checking", data)


    this.beneficiary_service
      .retrieve_guard_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

    



        this.f_name = data.data.f_name
        this.M_name = data.data.M_name
        this.l_name = data.data.l_name
        this.res_address = data.data.res_address
        this.mail_address = data.data.mail_address
        this.digi_address = data.data.digi_address
        this.occupation = data.data.occupation

        this.showOthers = 'no'
      })

  }

  show_spouse(data)
  {
    console.log("checking", data)


    this.spousal_service
      .retrieve_spousal_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)


        this.res_address = data.data.res_address
        this.mail_address = data.data.mail_address
        this.digi_address = data.data.digi_address
        this.occupation = data.data.occupation

        this.f_name = basic.data.spouse_first_name
        this.l_name = basic.data.spouse_l_name
      
        this.res_address = basic.data.res_address
        this.mail_address = basic.data.mail_address
        this.digi_address = basic.data.digi_address
        this.showOthers = 'no'


      })

  }

  show_exec(data) {
    console.log("checking", data)


    this.beneficiary_service
      .retrieve_exec_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

      



        this.f_name = data.data.f_name
        this.M_name = data.data.M_name
        this.l_name = data.data.l_name
        this.res_address = data.data.res_address
        this.mail_address = data.data.mail_address
        this.digi_address = data.data.digi_address
        this.occupation = data.data.occupation

        this.showOthers = 'no'


      })

  }



  show_others() {
   
    this.f_name = ""
    this.M_name = ""
    this.l_name = ""
    this.res_address = ""
    this.mail_address = ""
    this.digi_address = ""
    this.occupation = ""

    this.showOthers = 'yes'
  }





  newProperty2() {

    //this.thirdFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.f_name = ''
    this.M_name = ''
    this.l_name = ''
    this.res_address = ''
    this.mail_address = ''
    this.digi_address = ''
    this.occupation = ''
    this.trust = ''
    this.beneficiary = ''
  }



  edit_form(data) {
    console.log("DATA TO UPDATE:::", data)
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.beneficiary_service
      .retrieve_trustee_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id

        this.f_name = data.data.f_name
        this.M_name = data.data.M_name
        this.l_name = data.data.l_name
        this.res_address = data.data.res_address
        this.mail_address = data.data.mail_address
        this.digi_address = data.data.digi_address
        this.occupation = data.data.occupation
        this.trust = data.data.trust
        this.beneficiary = data.data.beneficiary

        this.showOthers = 'no'


      })
  }



  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("trustee")
          .doc(data.id)
          .delete()
      });

    }
  }


  params


  save_trust(data) {


    let formData: any = this.thirdFormGroup.value as {};
    console.log("Data ", data);
    console.log("VALUE ", formData);
    this.step++;


    this.params = {
      trust: data,
      f_name: formData.f_name,
      l_name: formData.l_name,
      M_name: formData.M_name || "none",
      res_address: formData.res_address || "none",
      mail_address: formData.mail_address || "none",
      digi_address: formData.digi_address || "none",
      occupation: formData.occupation || "none",
      beneficiary: formData.beneficiary || ""


    };

    console.log(" this.params ", this.params);


    this.beneficiary_service.save_trustee(this.params)

    this.new_form = 'no'

    this.callParent.emit("completed13");

  }

  summary() {
    this.new_form = 'no'
  }


  update(data) {
    let formData: any = this.thirdFormGroup.value as {};
    console.log("VALUE ", formData);
    console.log("DATA---->> ", data);

    this.params = {
      trust: data,
      f_name: formData.f_name,
      l_name: formData.l_name,
      M_name: formData.M_name || "none",
      res_address: formData.res_address || "none",
      mail_address: formData.mail_address || "none",
      digi_address: formData.digi_address || "none",
      occupation: formData.occupation || "none",
      beneficiary: formData.beneficiary || ""

    };

    console.log(" this.params ", this.params);
    console.log(" this.basic_doc_id ", this.basic_doc_id);

    this.beneficiary_service.update_trustee(this.basic_doc_id, this.params)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed13");
  }
}
