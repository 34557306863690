import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";

import { DependantsService } from "../../shared/services/dependants.service";
import { SpousalService } from "../../shared/services/spousal.service";
import { ChildrenService } from "../../shared/services/children.service";

@Component({
  selector: "app-will-reading",
  templateUrl: "./will-reading.component.html",
  styleUrls: ["./will-reading.component.css"],
})
export class WillReadingComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  trustee_trust_no;
  trustee_trust;
  ch_benefi;
  addG;
  listProp = "no"
  others: any;
  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  gifts_allo;
  bene;

  other_people = ''
  will_benefit = ''
  instructions = ''
  ben_name = ''
  person_phone = ''
  person_name = ''
  startAgain = "no"
  items;
  all_list = [];
  spouses;
  spouse_first_name;
  spouse_l_name;
  children_list;
  dependants;
  guardian;

  selected: any;

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    private db: AngularFirestore,
    public spousal_service: SpousalService,
    public child_service: ChildrenService,
    public dependants_service: DependantsService,
  ) { }

  ngOnInit() {

    this.alList()
    this.fourthFormGroup = this._formBuilder.group({
      ben_name: [""],
      will_benefit: [""],
      instructions: [""],
      other_people: [""],
      person_phone: [""],
      
      


    });

    this.beneficiary_service
      .retrieve_will()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

       

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id

          this.person_name = data.data.person_name
          this.person_phone = data.data.person_phone
          this.will_benefit = data.data.will_benefit
          this.instructions = data.data.instructions
          this.ben_name = data.data.ben_name


        }
      })


    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic2) => {
        console.log("BENEFICIARIES:::", basic2)
        this.bene = basic2




      })
  }

  form1() {
    console.log(this.firstFormGroup.value);
  }

  public modelChange(str: string) {

  }

  newProperty2() {

    this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.person_name = ""
    this.person_phone = ""
    this.will_benefit = ""
    this.instructions = ""
    this.startAgain = "yes"
    // this.other_people = ""
  }



  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"

    this.beneficiary_service
      .retrieve_will_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id

        this.person_name = basic.data.person_name
        this.person_phone = basic.data.person_phone
        this.will_benefit = basic.data.will_benefit
        this.instructions = basic.data.instructions
        this.ben_name = basic.data.ben_name
        this.other_people = basic.data.other_people


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("will_reading")
          .doc(data.id)
          .delete()
      });

    }
  }





  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_will(formData)

    this.new_form = 'no'

    this.callParent.emit("completed16");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.update_will(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed16");

  }


  alList() {
    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic2) => {
        console.log("BENE REQUIR:::", basic2)
        this.bene = basic2

        console.log("BENE REQUI his.beneR:::", this.bene)
        this.items = [];


        for (var name of this.bene) {
          console.log("BEN NAME", name);
          this.all_list.push({
            name: name.data.org_name || name.data.first_name + " " + name.data.last_name
          });
        }
        console.log("BENE all_list LISTSS::: ", this.all_list);

        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })



    this.spousal_service
      .retrieve_spousal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
        console.log("spouses all_list LISTSS::: ", this.all_list);



        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
          this.spouse_first_name = data.data.spouse_first_name
          this.spouse_l_name = data.data.spouse_l_name



          console.log("firstname:::", this.spouse_l_name)

        }
      })


    this.child_service
      .retrieve_child()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);



        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id


        }
      })



    this.dependants_service
      .retrieve_dependants()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.dependants = basic2

        for (var name of this.dependants) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }

    
        console.log("dependants all_list LISTSS::: ", this.all_list);


        console.log("ITEM LISTSS::: ", this.items);

        if (basic2.length > 0) {
          let data = basic2[0];
          this.basic_doc_id = data.id


        }
      })
    
    
    
    this.beneficiary_service
      .retrieve_guardian()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.guardian = basic2

        for (var name of this.guardian) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.f_name + " " + name.data.l_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);


      })

    console.log("WHAT IN all_list", this.all_list)
  }
}
