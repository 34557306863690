import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AddressValidator } from "src/validators/digital";

@Component({
  selector: "app-executor",
  templateUrl: "./executor.component.html",
  styleUrls: ["./executor.component.css"],
})
export class ExecutorComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  name;
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;

  f_name = ''
  M_name = ''
  l_name = ''
  phone = ''
  res_address = ''
  mail_address = ''
  digi_address = ''
  occupation = ''
  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    
    this.fourthFormGroup = this._formBuilder.group({
      f_name: ["", Validators.required],
      M_name: [""],
      l_name: ["", Validators.required],
      phone: ["", Validators.required],
      res_address: [""],
      mail_address: [""],
      digi_address: ['', Validators.compose([Validators.required, AddressValidator.isValid])],
      occupation: [""],
    });


    this.beneficiary_service
      .retrieve_executors()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
         

          this.f_name = data.data.f_name
          this.M_name = data.data.M_name
          this.l_name = data.data.l_name
          this.phone = data.data.phone
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.occupation = data.data.occupation


        }
      })
  }



  public modelChange(str: string) {

  }

  newProperty2() {

    // this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.f_name = ''
    this.M_name = ''
    this.l_name = ''
    this.phone = ''
    this.res_address = ''
    this.mail_address = ''
    this.digi_address = ''
    this.occupation = ''
  }



  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.beneficiary_service
      .retrieve_exec_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id


        this.f_name = basic.data.f_name
        this.M_name = basic.data.M_name
        this.l_name = basic.data.l_name
        this.phone = basic.data.phone
        this.res_address = basic.data.res_address
        this.mail_address = basic.data.mail_address
        this.digi_address = basic.data.digi_address
        this.occupation = basic.data.occupation


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("executors")
          .doc(data.id)
          .delete()
      });

    }
  }



  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_executors(formData)

    this.new_form = 'no'

    this.callParent.emit("completed10");

  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.update_executors(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed10");

  }
}
